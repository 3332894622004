<template>
  <FormWrapper
    :form="form"
    :hideForm="hideForm"
    :handleOpened="() => handleProps({ key: 'opened', value: !form.opened })"
    @getData="importAttendanceData(null, $event)"
    :hasPreConsultationValue="hasAttendanceValues('preConsultation')"
  >
    <b-row>
      <b-col>
        <b-row>
          <b-col>
            <div class="form-group mb-0">
              <div class="input-eye">
                <p class="tag-eye borderD"><EyeFill /> D</p>
                <input
                  autocomplete="off"
                  id="avsc-direito"
                  :value="form.fields.olhoDireito"
                  :disabled="!canEdit"
                  @input="el => debounceInput('olhoDireito', el.target.value)"
                  @click="setActiveForm('avscDireito')"
                  type="text"
                  class="form-control borderDInput"
                  placeholder="Resultado"
                />
              </div>
              <MultipleEyesOptions
                :form="form"
                :options="options"
                activeInput="avscDireito"
                :setActiveInput="this.setActiveForm"
                @select="({ key, value }) => this.updateForm(key, value)"
                @blur="updateMedicalRecord(form)"
                v-if="activeForm === 'avscDireito' && canEdit"
              />
            </div>
            <div class="form-group mb-0">
              <div class="input-eye">
                <p class="tag-eye borderE"><EyeFill /> E</p>
                <input
                  autocomplete="off"
                  id="avsc-esquerdo"
                  :value="form.fields.olhoEsquerdo"
                  :readonly="!canEdit"
                  :disabled="!canEdit"
                  @input="el => debounceInput('olhoEsquerdo', el.target.value)"
                  @click="setActiveForm('avscEsquerdo')"
                  type="text"
                  class="form-control borderEInput"
                  placeholder="Resultado"
                />
              </div>
              <MultipleEyesOptions
                :form="form"
                :options="options"
                activeInput="avscEsquerdo"
                :setActiveInput="this.setActiveForm"
                @select="({ key, value }) => this.updateForm(key, value)"
                @blur="updateMedicalRecord(form)"
                v-if="activeForm === 'avscEsquerdo' && canEdit"
              />
            </div>
          </b-col>
        </b-row>
      </b-col>
      <button
        class="btn blue-underline button-copy center"
        @click="copyEyeValues"
        v-if="canEdit"
        v-b-tooltip.hover title="Copiar valores O.D para O.E"
      >
        <Copy />
      </button>
    </b-row>
  </FormWrapper>
</template>

<script>
import { track } from '@/utils/mixPanel'
import { debounce } from 'lodash';
import { mapState, mapActions, mapGetters } from 'vuex'
import attendanceDataMixin from '@/mixins/Attendance/attendanceDataMixin'

export default {
  components: {
    FormWrapper: () => import('@/components/Attendance/Forms/FormWrapper'),
    EyeFill: () => import('@/assets/icons/eye-fill.svg'),
    MultipleEyesOptions: () => import('@/components/Attendance/Forms/Components/MultipleEyesOptions'),
    Copy: () => import('@/assets/icons/copy.svg'),
  },
  computed: {
    ...mapState({
      canEdit: state => state.attendance.canEdit,
      form: state => state.attendance.form.avscAcuidadeVisualSemCorrecao,
      medicalRecordsStore: state => state.attendance.medicalRecordsStore
    }),
    ...mapGetters('attendance', ['hasPreConsultation', 'preConsultation'])
  },
  data() {
    return {
      activeForm: String,
      options: [
        ['20/10', '20/15', '20/20', '20/25', '20/30'],
        ['20/40', '20/50', '20/70', '20/100', '20/200'],
        ['20/400', 'CD 3m', 'CD 2m', 'CD 1m', 'CD 0,5m'],
        ['MM', 'PL', 'SPL']
      ]
    }
  },
  mixins: [attendanceDataMixin('medicalRecordsStore')],
  mounted() {
    this.debounceInput = debounce(this.onInput, 300)
  },
  methods: {
    ...mapActions('attendance/form', ['updateMedicalRecord', 'destroyMedicalRecord']),
    ...mapActions('attendance/form/avscAcuidadeVisualSemCorrecao', ['handleFields', 'handleProps']),
    setActiveForm(FormName) {
      this.activeForm = FormName
    },
    onInput(key, value) {
      this.handleFields({ key, value })
      this.updateMedicalRecord(this.form)
    },
    updateForm(key, value) {
      this.handleFields({ key, value })
    },
    copyEyeValues() {
      this.updateForm('olhoEsquerdo', this.form.fields.olhoDireito)
      setTimeout(() => { this.updateMedicalRecord(this.form) }, 500)
      track('copy_eye_values', {
          'module': 'Acuidade Visual Sem Correcao'
      });
    },
    hideForm(){
      this.handleProps({ key: 'showing', value: false })
      this.destroyMedicalRecord(this.form)
      this.form.fields.olhoDireito = null
      this.form.fields.olhoEsquerdo = null
    },
    async importAttendanceData(medicalRecords = null, type) {
      const data = medicalRecords || this[type].medical_records
      const attendanceData = data.find(el => { return el.type === 'avsc-acuidade-visual-sem-correcao'})

      if (!attendanceData?.id) return
      Object.keys(attendanceData.value).map(key => {
        if (attendanceData.value[key]) 
          this.updateForm(`${key}`, attendanceData.value[key])
      })
      await this.updateMedicalRecord(this.form)
    },
  }
}
</script>
<style lang="scss" scoped>
  .borderD {
    border-radius: 8px 0 0 0 !important;
    border-bottom: 0px solid var(--neutral-300) !important;
  }
  .borderE {
    border-radius: 0 0 0 8px !important;
  }
  .borderDInput {
    border-radius: 0 8px 0 0 !important;
    border-bottom: 0px solid var(--neutral-300) !important;
  }
  .borderEInput {
    border-radius: 0 0 8px 0 !important;
  }
</style>
